import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Report } from 'features/Rapports/RapportType';
import DetailsButton from './DetailsButton';

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any
  }
}


interface ReportTableProps {
  reports: Report[];
}

const headerColor = '#e3e8e4'

const ReportTable = (props: ReportTableProps) => {
  const { reports } = props;


  const columns = [
    {
      name: 'Bâtiment de provenance',
      label: 'Bâtiment de provenance',
      options: {
        setCellProps: () => ({ style: { fontWeight: 'bold' } }),
        setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),

      },
    },
    {
      name: 'Type',
      label: 'Type',
      options: {
        setCellProps: () => ({ style: { fontWeight: 'bold' } }),
        setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),

      },
    },
    {
      name: 'Catégorie',
      label: 'Catégorie',
      options: {
        setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),

      },
    },
    {
      name: 'Nom',
      label: 'Nom',
      options: {
        setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),

      },
    },
    {
      name: 'Date',
      label: 'Date',
      options: {
        customBodyRender: (value) => {
          return <span style={{ fontWeight: 'normal' }}>{new Date(value).toLocaleString('fr-FR')}</span>;
        },
        setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),
      },
    },
    {
      name: 'Détails',
      label: 'Détails',
      options: {
        customBodyRender: (value) => {
          const reportData = reports.find((el) => el.id === value);
          return <DetailsButton report={reportData} />;
        },
        setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),
      },
    },
  ];




  const data = reports.map((report) => {
    return [
      report.reportShip.name,
      report.ship.type,
      report.ship.category,
      report.ship.name,
      report.creationDate,
      report.id,
    ]
  });

  const options = {
    filter: true,
    responsive: "standard",
    selectableRows: "none",
    print: false,
    download: true,
    textLabels: {
      body: {
        noMatch: "Aucun enregistrement correspondant n'a été trouvé",
        toolTip: "Trier",
      },
      pagination: {
        next: "Suivant",
        previous: "Précédent",
        rowsPerPage: "Lignes par page :",
        displayRows: "sur",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger CSV",
        print: "Imprimer",
        viewColumns: "Voir les colonnes",
        filterTable: "Filtrer le tableau",
      },
      filter: {
        all: "Tous",
        title: "FILTRES",
        reset: "RÉINITIALISER",
      },
      viewColumns: {
        title: "Voir les colonnes",
        titleAria: "Voir/Masquer les colonnes du tableau",
      },
      selectedRows: {
        text: "ligne(s) sélectionnée(s)",
        delete: "Supprimer",
        deleteAria: "Supprimer les lignes sélectionnées",
      },
    },
    rowsPerPage: 15,
  };

  return (
    <MUIDataTable
      title={"Liste des Rapports"}
      data={data}
      columns={columns}
      options={options}
    />

  );
}

export default ReportTable;